import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'

export default class extends Controller {
  static targets = ['timestamp']
  static values = {
    format: { default: 'M/D/YY h:mm a', type: String }
  }

  connect() {
    this.timestampTarget.textContent = dayjs(
      this.timestampTarget.attributes.datetime.value
    ).format(this.formatValue)
  }
}
