import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['input', 'select', 'submit']

  async change() {
    if (this.selectTarget.value) {
      const response = await fetch(
        `/customer/accounts_vehicle_types/${this.selectTarget.value}`,
        {
          headers: {
            Accept: 'application/json'
          }
        }
      )

      if (response.ok) {
        const serviceId = await response.json()
        const date = new Date(this.inputTarget.value)
          .toISOString()
          .split('T')[0]

        await get(
          `/customer/delivery_time_slots?date=${date}&service_id=${serviceId}`,
          {
            responseKind: 'turbo-stream'
          }
        )
      }
    }
  }
}
