import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'

export default class extends Controller {
  static targets = ['timestamp']

  connect() {
    this.setTimeAgo()

    this.startRefreshing()
  }

  setTimeAgo() {
    this.timestampTarget.textContent = dayjs().to(
      dayjs(this.timestampTarget.attributes.datetime.value)
    )
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => this.setTimeAgo(), 1000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  disconnect() {
    this.stopRefreshing()
  }
}
